'use client'

import { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
import * as THREE from 'three'

const vertexShader = `
  precision mediump float;
  attribute float size;
  varying vec3 vPosition;
  
  void main() {
    vPosition = position;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    gl_PointSize = size * (300.0 / -mvPosition.z);
    gl_Position = projectionMatrix * mvPosition;
  }
`

const fragmentShader = `
  precision mediump float;
  uniform float time;
  varying vec3 vPosition;

  void main() {
    vec3 color = normalize(vPosition) * 0.5 + 0.5;
    gl_FragColor = vec4(color, 1.0);
  }
`

function ParticleSystem({ url }) {
  const { scene } = useGLTF(url)
  const shaderRef = useRef()

  const particles = useMemo(() => {
    const vertices = []
    scene.traverse((child) => {
      if (child.isMesh) {
        const positionAttribute = child.geometry.getAttribute('position')
        for (let i = 0; i < positionAttribute.count; i++) {
          const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i)
          vertices.push(vertex)
        }
      }
    })
    return vertices
  }, [scene])

  const [positions, sizes] = useMemo(() => {
    const positions = new Float32Array(particles.length * 3)
    const sizes = new Float32Array(particles.length)
    particles.forEach((particle, i) => {
      positions.set([particle.x, particle.y, particle.z], i * 3)
      sizes[i] = Math.random() * 0.1 + 0.05
    })
    return [positions, sizes]
  }, [particles])

  useFrame((state) => {
    if (shaderRef.current) {
      shaderRef.current.uniforms.time.value = state.clock.elapsedTime
    }
  })

  return (
    <points>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-size"
          count={sizes.length}
          array={sizes}
          itemSize={1}
        />
      </bufferGeometry>
      <shaderMaterial
        ref={shaderRef}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={{ time: { value: 0 } }}
        transparent
        depthWrite={false}
      />
    </points>
  )
}

function RingParticles({ isMouseDown }) {
  const MIN_PARTICLE_SIZE = 0.2; 
  const MAX_PARTICLE_SIZE = 0.4;
  const ringRef = useRef()
  const amplitudeRef = useRef(1.0)
  const rotationSpeedRef = useRef(new THREE.Vector2(0, 0))
  const lastMousePositionRef = useRef(new THREE.Vector2())
  const orbitControlsRef = useRef()
  const { camera, gl } = useThree()

  const [positions, sizes, ringIndices, originalPositions, randomOffsets] = useMemo(() => {
    const positions = []
    const sizes = []
    const ringIndices = []
    const originalPositions = []
    const randomOffsets = []
    const ringCount = 3
    const particlesPerRing = 300
    const radius = 12.5

    for (let i = 0; i < ringCount; i++) {
      for (let j = 0; j < particlesPerRing; j++) {
        const angle = (j / particlesPerRing) * Math.PI * 2
        const x = Math.cos(angle) * radius
        const z = Math.sin(angle) * radius
        positions.push(x, 0, z)
        originalPositions.push(x, 0, z)
        sizes.push(0.3)
        ringIndices.push(i)
        randomOffsets.push(
          (Math.random() - 0.5) * 2,
          (Math.random() - 0.5) * 2,
          (Math.random() - 0.5) * 2
        )
      }
    }

    return [
      new Float32Array(positions),
      new Float32Array(sizes),
      new Float32Array(ringIndices),
      new Float32Array(originalPositions),
      new Float32Array(randomOffsets)
    ]
  }, [])

  const waveVertexShader = `
    precision mediump float;
    uniform float time;
    uniform float amplitudeMultiplier;
    uniform vec2 rotationSpeed;
    uniform float resetProgress;
    attribute float size;
    attribute float ringIndex;
    attribute vec3 originalPosition;
    attribute vec3 randomOffset;
    varying vec3 vColor;

    vec3 hsl2rgb(vec3 c) {
      vec3 rgb = clamp(abs(mod(c.x * 6.0 + vec3(0.0, 4.0, 2.0), 6.0) - 3.0) - 1.0, 0.0, 1.0);
      return c.z + c.y * (rgb - 0.5) * (1.0 - abs(2.0 * c.z - 1.0));
    }

    vec3 getColor(float t) {
      float hue = mod(t + time * 0.1, 1.0);
      return hsl2rgb(vec3(hue, 0.7, 0.5));
    }

    float noise(vec2 p) {
      return sin(p.x) * cos(p.y);
    }

    void main() {
      vec3 pos = mix(position + randomOffset * (1.0 - resetProgress), originalPosition, resetProgress);
      float amplitude = (0.5 + ringIndex * 0.2) * amplitudeMultiplier;
      float frequency = 0.3 + ringIndex * 0.1;

      float wave = sin(pos.x * frequency + time) * cos(pos.z * frequency + time) * amplitude;
      wave += noise(vec2(pos.x * 0.05 + time * 0.2, pos.z * 0.05 + time * 0.2)) * amplitude * 0.2;

      pos.y = wave;

      vec4 mvPosition = modelViewMatrix * vec4(pos, 1.0);
      gl_Position = projectionMatrix * mvPosition;
      
      gl_PointSize = size * (300.0 / -mvPosition.z);

      // Calculate angle for color interpolation
      float angle = atan(pos.z, pos.x);
      float normalizedAngle = (angle + 3.14159) / (2.0 * 3.14159);

      // Get color based on normalized angle
      vec3 color = getColor(normalizedAngle);

      // Adjust color based on wave height
      color = mix(color, vec3(1.0), wave * 0.2);

      vColor = color;
    }
  `

  const waveFragmentShader = `
    precision mediump float;
    varying vec3 vColor;

    void main() {
      vec2 cxy = 2.0 * gl_PointCoord - 1.0;
      float r = dot(cxy, cxy);
      if (r > 1.0) discard;
      gl_FragColor = vec4(vColor, 1.0);
    }
  `

  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      vertexShader: waveVertexShader,
      fragmentShader: waveFragmentShader,
      uniforms: {
        time: { value: 0 },
        amplitudeMultiplier: { value: 1.0 },
        rotationSpeed: { value: new THREE.Vector2(0, 0) },
        resetProgress: { value: 0 }
      },
      transparent: true,
      depthWrite: false
    })
  }, [])

  useFrame((state) => {
    shaderMaterial.uniforms.time.value = state.clock.elapsedTime

    const targetAmplitude = isMouseDown ? 2.0 : 1.0
    amplitudeRef.current += (targetAmplitude - amplitudeRef.current) * 0.1
    shaderMaterial.uniforms.amplitudeMultiplier.value = amplitudeRef.current

    shaderMaterial.uniforms.rotationSpeed.value.copy(rotationSpeedRef.current)

    rotationSpeedRef.current.multiplyScalar(0.95)

    const targetResetProgress = isMouseDown ? 1 : 0
    shaderMaterial.uniforms.resetProgress.value += (targetResetProgress - shaderMaterial.uniforms.resetProgress.value) * 0.1

    const randomOffsetAttribute = ringRef.current.geometry.attributes.randomOffset
    for (let i = 0; i < randomOffsetAttribute.count; i++) {
      randomOffsetAttribute.array[i * 3] += (Math.random() - 0.5) * 0.01
      randomOffsetAttribute.array[i * 3 + 1] += (Math.random() - 0.5) * 0.01
      randomOffsetAttribute.array[i * 3 + 2] += (Math.random() - 0.5) * 0.01
    }
    randomOffsetAttribute.needsUpdate = true
  })

  const handleMouseMove = useCallback(
    (event) => {
      if (isMouseDown && orbitControlsRef.current) {
        const { clientX, clientY } = event
        const deltaX = clientX - lastMousePositionRef.current.x
        const deltaY = clientY - lastMousePositionRef.current.y

        const rotationX = (deltaY / window.innerHeight) * Math.PI
        const rotationY = (deltaX / window.innerWidth) * Math.PI

        rotationSpeedRef.current.set(rotationY, rotationX)

        lastMousePositionRef.current.set(clientX, clientY)
      }
    },
    [isMouseDown]
  )

  const handleMouseDown = useCallback((event) => {
    lastMousePositionRef.current.set(event.clientX, event.clientY)
  }, [])

  useEffect(() => {
    const canvas = gl.domElement
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('mousedown', handleMouseDown)

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('mousedown', handleMouseDown)
    }
  }, [gl.domElement, handleMouseMove, handleMouseDown])

  return (
    <>
      <points ref={ringRef}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={positions.length / 3}
            array={positions}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-size"
            count={sizes.length}
            array={sizes}
            itemSize={1}
          />
          <bufferAttribute
            attach="attributes-ringIndex"
            count={ringIndices.length}
            array={ringIndices}
            itemSize={1}
          />
          <bufferAttribute
            attach="attributes-originalPosition"
            count={originalPositions.length / 3}
            array={originalPositions}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-randomOffset"
            count={randomOffsets.length / 3}
            array={randomOffsets}
            itemSize={3}
          />
        </bufferGeometry>
        <primitive object={shaderMaterial} attach="material" />
      </points>
      <OrbitControls
        ref={orbitControlsRef}
        args={[camera, gl.domElement]}
        enableDamping
        dampingFactor={0.05}
        rotateSpeed={0.5}
        enablePan={false}
        enableZoom={false}
      />
    </>
  )
}

function Scene() {
  const [isMouseDown, setIsMouseDown] = useState(false)
  const { gl } = useThree()

  useEffect(() => {
    const canvas = gl.domElement
    const handleMouseDown = () => setIsMouseDown(true)
    const handleMouseUp = () => setIsMouseDown(false)

    canvas.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [gl])

  return (
    <>
      <color attach="background" args={['#000000']} />
      <ParticleSystem url="/cvbox.glb" />
      <RingParticles isMouseDown={isMouseDown} />
      <ambientLight intensity={0.5} />
      <pointLight position={[13, 13, 13]} />
    </>
  )
}
export default function Component() {
  return (
  <div className="w-full h-screen bg-black">
    <Canvas camera={{ position: [13, 13, 13] }} style={{ width: '100vw', height: '100vh' }}>>
      <Scene />
    </Canvas>
  </div>
);
}
